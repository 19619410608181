<template>
  <div class="navigation d-flex flex-column align-items-center pt-5 page-wrap">
    <div class="h2 d-block py-2 fw-bold" v-for="route in routes" :key="route.name">
      <router-link class="text-black text-decoration-none" :to="route.url">{{ route.name }}</router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NavigationView',
  components: {},
  data() {
    const navigation = this.$store.getters.navigation;
    let routes = [];

    // eslint-disable-next-line no-unused-vars
    for (const [name, item] of Object.entries(navigation)) {
      if(item.inMenu) {
        routes.push(item);
      }
    }

    return {
      routes
    }
  }
}
</script>
