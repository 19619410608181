import { createRouter, createWebHistory } from 'vue-router'
import PageView from "@/views/PageView";
import NavigationView from "@/views/NavigationView";

const routes = [
  {
    path: '/:ticket',
    name: 'home',
    meta: {
      hasLoadingAnimation: true
    },
    component: PageView
  },
  {
    path: '/:ticket/:path',
    name: 'any',
    meta: {
      hasLoadingAnimation: true
    },
    component: PageView
  },
  {
    path: '/:ticket/navigation',
    name: 'navigation',
    component: NavigationView,
    meta: {
      hasLoadingAnimation: false,
      transition: 'slide-left'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const shortcuts = [];

window.routes = routes;

export default router
export { shortcuts };
