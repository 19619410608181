<template>
  <div class="splash-screen vh-100 w-100 d-flex justify-content-center align-items-center bg-danger">
    <div class="text-white fs-4">{{error}}</div>
  </div>
</template>

<script>
export default {
  name: 'error-screen',
  props: {
    error: String
  }
}
</script>

<style lang="scss">

</style>