<template>

  <div class="page-wrap">
    <page-renderer class="page-iframe" :page="page" @rendered="onRendered"/>
  </div>
</template>

<script>
import PageRenderer from "@/components/PageRenderer";

export default {
  name: 'HomeView',
  components: {PageRenderer},
  data() {
    let page = this.$route.meta.page;
    if(typeof page === "undefined") {
      const path = typeof this.$route.params.path !== "undefined" ? this.$route.params.path : 'home'
      page = this.$store.getters.navigation[path].page;
    }

    return {
      lastScrollTop: 0,
      scrollDirection: 'up',
      loaded: false,
      page
    }
  },

  methods: {
    onRendered() {
      const iFrame = document.querySelector('.page-iframe');
      if(iFrame) {
        iFrame.contentWindow.addEventListener('scroll', e => this.onScroll(e))
      }
      this.$emit('loaded');
    },

    onScroll(e) {
      const elementWindow = e.currentTarget;
      const elementDocument = e.target;
      const st = elementWindow.scrollY || elementDocument.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
      if (st > this.lastScrollTop){
        if(this.scrollDirection === "up") {
          this.scrollDirection = 'down';
          this.$emit('scroll', 'down')
        }
      } else {
        if(this.scrollDirection === "down") {
          this.scrollDirection = 'up';
          this.$emit('scroll', 'up')
        }
      }
      this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    }
  }
}
</script>


<style lang="scss">
iframe {
  opacity: 0;
  width: 100vw;
  height: calc(100vh - 73px);
  transition: opacity .5s;
  &.show {
    opacity: 1;
  }
}
</style>