<template>
  <style-wrap>
    <page-wrap v-if="initialized"/>
    <splash-screen v-if="!initialized && !error" />
    <error-screen v-if="error" :error="error"></error-screen>

    <div v-if="!initialized && !error">
      <page-renderer v-for="(item) in navigation" :key="item.name" :page="item.page" @rendered="onRendered"/>
    </div>
  </style-wrap>
</template>

<script>
import {defineAsyncComponent} from "vue";
import SplashScreen from "@/components/SplashScreen";
import StyleWrap from "@/components/StyleWrap";
import ErrorScreen from "@/components/ErrorScreen";


export default {
  name: 'App',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    PageRenderer: defineAsyncComponent(() =>
        import('./components/PageRenderer.vue')
    ),
    ErrorScreen,
    StyleWrap,
    PageWrap: defineAsyncComponent(() =>
        import('./PageWrap.vue')
    ),
    SplashScreen
  },
  data() {
    return {
      initialized: false,
      error: null,
      navigation: {}
    }
  },
  beforeCreate() {
    this.$store.dispatch('fetchPEP')
    window.addEventListener('sw-register', () => this.setupPEP());

  },
  methods: {
    setupPEP() {
      console.log('test?')
      if(!this.initialized) {
        this.$store.dispatch('fetchPEP').then(() => {
          this.setupHead();
          this.navigation = Object.assign({}, this.$store.getters.navigation);
          console.log(this.navigation);
          // this.initialized = true;
        }).catch((error) => {
          this.error = error;
        });
      }
    },

    setupHead() {
      const head = document.head;
      const faviconSVG = head.querySelector('[rel="icon"][type="image/svg+xml"]'),
            favicon16 = head.querySelector('[rel="icon"][sizes="16x16"]'),
            favicon32 = head.querySelector('[rel="icon"][sizes="32x32"]'),
            maskIcon = head.querySelector('[rel="mask-icon"]'),
            manifest = head.querySelector('[rel="manifest"]'),
            appleTouchIcon = head.querySelector('[rel="apple-touch-icon"]'),
            msTileImage = head.querySelector('meta[name="msapplication-TileImage"]'),
            msTileColor = head.querySelector('meta[name="msapplication-TileColor"]'),
            themeColor = head.querySelector('meta[name="theme-color"]'),
            appleWebAppBarStyle = head.querySelector('meta[name="apple-mobile-web-app-status-bar-style"]'),
            appleWebAppTitle = head.querySelector('meta[name="apple-mobile-web-app-title"]'),
            title = head.querySelector('title');

      faviconSVG.setAttribute('href', this.$store.getters.icons.faviconSVG);
      favicon16.setAttribute('href', this.$store.getters.icons.favicon16);
      favicon32.setAttribute('href', this.$store.getters.icons.favicon32);
      maskIcon.setAttribute('href', this.$store.getters.icons.maskIcon);
      appleTouchIcon.setAttribute('href', this.$store.getters.icons.appleTouchIcon);
      msTileImage.setAttribute('content', this.$store.getters.icons.msTileImage);
      msTileColor.setAttribute('content', this.$store.getters.colors.themeColor);
      themeColor.setAttribute('content', this.$store.getters.colors.themeColor);
      appleWebAppBarStyle.setAttribute('content', this.$store.getters.colors.themeColor);
      appleWebAppTitle.setAttribute('content', this.$store.getters.title);
      console.log(this.$store.getters.manifest)
      manifest.setAttribute('href', this.$store.getters.manifest);
      title.innerHTML = this.$store.getters.title;
    },

    onRendered(page) {
      let allPagesRendered = true;
      for (const [name, item] of Object.entries(this.navigation)) {
        if(item.page === page) {
          this.navigation[name].rendered = true;
        }
        if(this.navigation[name].rendered !== true) allPagesRendered = false;
      }

      if(allPagesRendered) {
        this.initialized = true;
      }
    }
  }
}
</script>