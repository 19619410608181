import { createStore } from 'vuex'
import axios from "axios";

function parseURL(url, config) {
  const regex = /{(.*?)}/gm;
  const matches = url.match(regex);
  if(matches) {
    matches.forEach(match => {
      const input = match.replace('{', '').replace('}', '');
      const inputPath = input.split('.');

      let replacer = config;
      inputPath.forEach(key => {
        replacer = replacer[key];
      })

      url = url.replace(match, replacer);
    })
  }

  return url
}

export default createStore({
  state: {
    ticket: null,
    title: "PEP",
    manifest: "manifest.json",
    colors: {
      headerColor: "#FFFFFF",
      themeColor: "#000000"
    },
    icons: {
      appleTouchIcon: "/img/icons/apple-touch-icon.png",
      favicon16: "/img/icons/favicon-16x16.png",
      favicon32: "/img/icons/favicon-32x32.png",
      faviconSVG: "/img/icons/favicon.ico",
      maskIcon: "/img/icons/favicon.ico",
      msTileImage: "/img/icons/mstile-150x150.png"
    },
    images: {
      logo: "#",
      splash: "#"
    },
    shortcuts: {},
    navigation: {}
  },
  getters: {
    ticket: state => state.ticket,
    title: state => state.title,
    manifest: state => state.manifest,
    colors: state => state.colors,
    icons: state => state.icons,
    images: state => state.images,
    shortcuts: state => state.shortcuts,
    navigation: state => state.navigation,
    getPEP: state => state.pep
  },
  mutations: {
    SET_TICKET(state, ticket) {
      state.ticket = ticket
    },
    SET_TITLE(state, title) {
      state.title = title
    },
    SET_MANIFEST(state, manifest) {
      state.manifest = manifest
    },
    SET_COLORS(state, colors) {
      state.colors = colors
    },
    SET_ICONS(state, icons) {
      state.icons = icons
    },
    SET_IMAGES(state, images) {
      state.images = images
    },
    SET_NAVIGATION(state, navigation) {
      state.navigation = navigation
    },
    SET_SHORTCUTS(state, shortcuts) {
      state.shortcuts = shortcuts
    },
    SET_PEP(state, pep) {
      state.pep = pep
    }
  },
  actions: {
    async fetchPEP({ commit }) {
      return new Promise((resolve, reject) => {
        try {
          const ticketPath = window.location.pathname;
          const ticketPathParts = ticketPath.split('/');
          const ticketID = ticketPathParts[1];
          if(!ticketID) throw "TicketID not defined";

          axios.get('https://www.ticketpark.ch/pep-dummy/index.php?t=' + ticketID).then(data => {
          // axios.get('https://pep-api.frappant.ch/' + ticketID +  '.json').then(data => {
            const config = data.data;

            // eslint-disable-next-line no-unused-vars
            const navigation = {};
            navigation.home = {
              name: "Home",
              url: '/' + ticketID,
              page: parseURL(config.pep.home, config),
              inMenu: true
            };

            for (const [name, page] of Object.entries(config.pep.navigation.main)) {
              navigation[name.toLowerCase()] = {
                name: name,
                url: '/' + ticketID + '/' + name.toLowerCase(),
                page: parseURL(page, config),
                inMenu: true
              };
            }

            for (const [name, page] of Object.entries(config.pep.navigation.shortcuts)) {
              navigation[name] = {
                name: name,
                url: '/' + ticketID + '/' + name.toLowerCase(),
                page: parseURL(page.url, config),
                inMenu: false,
              };
            }

            //fetch all sites while loading up app
            // eslint-disable-next-line no-unused-vars
            for (const [name, item] of Object.entries(navigation)) {
              axios.get(item.url)
            }

            console.log(config)
            commit('SET_TICKET', ticketID)
            commit('SET_TITLE', config.pep.name)
            commit('SET_MANIFEST', "https://www.ticketpark.ch/pep-dummy/manifest.php?id=" + ticketID)
            commit('SET_COLORS', config.colors)
            commit('SET_ICONS', config.icons)
            commit('SET_IMAGES', config.pep.images)
            commit('SET_SHORTCUTS', config.pep.navigation.shortcuts)
            commit('SET_NAVIGATION', navigation)
            resolve();

          }).catch(error => {
            throw error;
          })
        }
        catch (error) {
          reject(error)
        }
      });
    }
  },
  modules: {
  }
})
