<template>
  <div class="style-wrap" :style="cssVars">
    <slot/>
  </div>
</template>

<script>

export default {
  name: 'StyleWrap',
  computed: {
    cssVars () {
      return {
        '--bs-primary': this.$store.getters.colors.themeColor,
        '--bs-primary-rgb': this.hexToRgb(this.$store.getters.colors.themeColor)
      }
    }
  },
  methods: {
    hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? parseInt(result[1], 16) + "," + parseInt(result[2], 16) + "," + parseInt(result[3], 16) : null;
    }
  }
}
</script>

<style>
.style-wrap {
  height: 100vh;
  overflow: hidden;
}
</style>