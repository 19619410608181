<template>
  <div v-show="this.$store.getters.images.splash" class="splash-screen vh-100 w-100 d-flex justify-content-center align-items-center bg-primary">
    <img :src="this.$store.getters.images.splash" class="splash-image w-100 h-100" alt="logo"/>
  </div>
</template>

<script>
export default {
  name: 'splash-screen',
}
</script>

<style lang="scss">
.splash-image {
  object-fit: cover;
}
</style>