<template>
  <iframe :id="id" style="opacity: 1;" @load="iFrameLoad()" class="page-iframe" src="/empty.html" v-show="!error"></iframe>
  <ErrorScreen v-show="error" :error="error"/>
</template>

<script>
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import ErrorScreen from "@/components/ErrorScreen";

export default {
  name: 'page-renderer',
  props: {
    page: String
  },
  components: {
    ErrorScreen

  },
  data () {
    return {
      id: null,
      error: '',
    }
  },
  mounted () {
    this.id = "f" + uuidv4();
    // this.$emit('rendered', this.page)

  },
  methods: {
    iFrameLoad() {
      const that = this;
      const iFrame = document.querySelector('#' + this.id);

      const pageURL = new URL(this.page);

      //Hauptdomain von Seite
      const td = pageURL.host

      const ld = window.location.host

      // GET request for remote image in node.js
      axios({
        method: 'get',
        url: this.page,
      }).then(function (response) {
        const innerDocument = document.createElement('section');
        innerDocument.innerHTML = response.data;

        const styles = innerDocument.querySelectorAll('[rel="stylesheet"]');
        console.log(styles);
        const images = innerDocument.querySelectorAll('img');
        // eslint-disable-next-line no-unused-vars
        const scripts = innerDocument.querySelectorAll('script');

        styles.forEach(style => {
          const rd = style.href.indexOf("/" + that.$store.getters.ticket) >= 0 ? ld + "/" + that.$store.getters.ticket : ld;
          style.setAttribute('href', style.href.replace(rd, td))
        })
        images.forEach(image => {
          const rd = image.src.indexOf("/" + that.$store.getters.ticket) >= 0 ? ld + "/" + that.$store.getters.ticket : ld;
          image.setAttribute('src', image.src.replace(rd, td))
        })
        scripts.forEach(script => {
          if(script.src) {
            const rd = script.src.indexOf("/" + that.$store.getters.ticket) >= 0 ? ld + "/" + that.$store.getters.ticket : ld;
            script.setAttribute('src', script.src.replace(rd, td))
          }
        })

        const iFrameDocument = (iFrame.contentDocument) ? iFrame.contentDocument : iFrame.contentWindow.document;

        iFrameDocument.querySelector('html').innerHTML = innerDocument.innerHTML
        iFrameDocument.write(iFrameDocument.head.outerHTML + iFrameDocument.body.outerHTML);

        setTimeout(() => {
          that.$emit('rendered', that.page)
          // iFrame.classList.add('show');
        }, 100);
      }).catch(() => {
        if(window.navigator.onLine) {
          try {
            const newIFrame = document.createElement('iframe');

            newIFrame.src = that.page;
            newIFrame.style.opacity = "1";
            newIFrame.classList.add('page-iframe');
            iFrame.parentNode.insertBefore(newIFrame, iFrame)
            // iFrame.parentNode.appendChild(newIFrame);

            iFrame.remove();

            newIFrame.addEventListener('load', () => that.onRedirect());
          } catch (e) {
            // that.$set(that, 'error', e)
            that.setError(e)
          }
        } else {
          // that.$set(that, 'error', "you are offline")
          that.setError("you are offline")
        }

        that.$emit('rendered', that.page)
      });
    },

    setError(message) {
      this.error = message;
    },

    onRedirect() {
      // console.log('its a redirect');
      // console.log(e);
    },
  }
}
</script>